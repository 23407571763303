import Vue from 'vue';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/icon/iconfont.js';
import './assets/icon/iconfont.css';

import App from './App.vue';
import myutils from './utils/index';
import axios from 'axios';
//引入路由
import router from "./router";


//在自定义axios配置文件下面通过生产环境以及开发环境进行判断设置baseURL
axios.defaults.baseURL=
process.env.NODE_ENV==="development" ? "" : "https://www.gy429.com";
//////////////

Vue.prototype.axios=axios;
Vue.prototype.baseUrl=axios;
Vue.prototype.$myutils=myutils;
Vue.use(ElementUI);

Vue.config.productionTip = false
new Vue({
  router,
 
  render: h => h(App),
}).$mount('#app')
 