import Vue from "vue";
import VueRouter from "vue-router";


import Home from '../components/Home.vue';
import UserMd from '../components/UserMd.vue'
//import echartsRouters from "./practice/echarts"
//注册vue-router中的所有组件

Vue.use(VueRouter);
const routes=[{
    path:'/',
    name:'Home',
    component:Home,
    children:[//1、通过children属性，嵌套声明子级路由规则
{
    path:"usermd",
    component:UserMd
}
    ]
}];
const router=new VueRouter({
    mode:"history",//mode设置路由模式，不配置这个参数时默认为hash模式。
    routes,
    linkActiveClass:'active',
});
export default router;
